import React, { useState } from 'react';
import styled from 'styled-components';
import convertButtonImage from '../좋았쓰-밈.png';

// 스타일 정의
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;  // 컨테이너의 크기가 패딩 포함 크기로 계산됨

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Input = styled.textarea`
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  resize: vertical; // Allow users to resize the textarea vertically
  box-sizing: border-box;  // 인풋 박스 크기가 패딩 포함 크기로 계산됨

  @media (max-width: 768px) {
    font-size: 14px;
    height: 80px;
  }
`;

const ImageButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;

  img {
    width: 150px; // Adjust the width as needed
    height: auto;

    @media (max-width: 768px) {
      width: 120px; // Smaller button size for smaller screens
    }
  }
`;

const ResultBox = styled.div`
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  min-height: 50px;
  background-color: #f9f9f9;
  font-size: 16px;
  box-sizing: border-box;  // 결과 박스의 크기가 패딩 포함 크기로 계산됨

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Footer = styled.footer`
  margin-top: 40px;
  padding: 20px 0;
  background-color: #f1f1f1;
  font-size: 14px;
  color: #333;
  text-align: center;
  box-sizing: border-box;  // 푸터 크기가 패딩 포함 크기로 계산됨

  a {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px;
    
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 15px 0;
  }
`;

const TextConverter = () => {
  const [inputText, setInputText] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleConvert = async () => {
    if (loading) return; // Prevent multiple requests
    setLoading(true);
    try {
      const response = await fetch('https://api-joatss.devgyurak.com/api/joatss', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: inputText }),
      });

      if (!response.ok) {
        if (response.status === 400) {
          const errorData = await response.json();
          setResult(errorData[0]);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } else {
        const data = await response.json();
        setResult(data.answer);
      }
    } catch (error) {
      console.error('Error during conversion:', error);
      setResult('Error occurred during conversion.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h1>👍 좋았쓰! 변환기 👍</h1>
      <Input
        placeholder="바꿀 문장을 입력하고 현장냥이를 누르면 좋았쓰!"
        value={inputText}
        onChange={handleInputChange}
      />
      <ImageButton onClick={handleConvert}>
        <img src={convertButtonImage} alt="Convert" />
      </ImageButton>
      <ResultBox>
        {result ? result : '뭐가 좋았는지 여기 나오니까 좋았쓰!'}
      </ResultBox>
      <Footer>
        <div>개발자와 연락하기</div>
        <a href="mailto:contact@devgyurak.com">💌 이메일</a>
        <a href="https://velog.io/@devgyurak/posts">✍️ 블로그</a>
        <a href="https://www.linkedin.com/in/devgyurak/">👨‍💻 링크드인</a>
        <a href="https://github.com/leegyurak">😸 깃허브</a>
      </Footer>
    </Container>
  );
};

export default TextConverter;
